/** @jsx jsx */
import { jsx } from "theme-ui"

import Layout from "../components/layout"
import SEO from "../components/seo"

const SignupPage = () => (
  <Layout>
    <SEO title="Sign Up" />
    Sign Up page
  </Layout>
)

export default SignupPage
